@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f0f0ec;
}

#root {
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  /* font-family: "Roboto", "Segoe UI", Helvetica, Arial, Icon, sans-serif; */
  font-display: auto;
  font-size: 1rem;
  line-height: 1.54;
  text-rendering: optimizeLegibility;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #f0f0ec;
  color: #222;
}

.app-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  flex: 1;
}

hr {
  border-color: #222;
}

.blog-post {
  max-width: 700px;
  margin: 0 auto;
}

.blog-post h2 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 1rem;
}